import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private readonly isDarkModeSignal = signal<boolean>(false);

  constructor() {
    this.initializeDarkModeDetection();
  }

  private initializeDarkModeDetection(): void {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    this.isDarkModeSignal.set(mediaQuery.matches);

    mediaQuery.addEventListener('change', (event) => {
      this.isDarkModeSignal.set(event.matches);
    });
  }

  get isDarkMode(): boolean {
    return this.isDarkModeSignal();
  }
}
